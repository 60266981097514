import { Button, Container, Typography } from "@mui/material";
import CloseableModal from "./ClosableModal";
import { useSharedContext } from "../../SharedContext";
import { Trans, useTranslation } from "react-i18next";
import PartnerButton from "./PartnerButton";
import ROUTES from "../../routes";
import Navigate from "./Navigate";

export default function CartModal() {
  const { t, openCartModal, setOpenCartModal } = useSharedContext();
  const { i18n } = useTranslation();

  return (
    <CloseableModal
      sx={{ maxWidth: { xs: "500px", xl: i18n.language === "en" ? "650px" : "505px" } }}
      open={openCartModal}
      setOpen={setOpenCartModal}
    >
      <Typography variant="h5" component="h5">
        <Trans i18nKey="cartModalText" components={{ b: <b />, br: <br /> }} />
      </Typography>
      <Container
        sx={{
          display: "flex",
          gap: "10px",
          mt: "20px",
          justifyContent: "center",
          flexDirection: { xs: "column", sm: "row" },
          width: "fit-content",
        }}
        disableGutters
      >
        <Button sx={{ marginTop: "10px", px: 4 }} onClick={() => setOpenCartModal(false)} variant="contained">
          <Typography variant="h5" component="span">
            {t("continueShopping")}
          </Typography>
        </Button>
        <Navigate to={ROUTES.CART}>
          <PartnerButton
            onClick={() => {
              setOpenCartModal(false);
            }}
          >
            <Typography variant="h5" component="span">
              {t("proceedToCheckout")}
            </Typography>
          </PartnerButton>
        </Navigate>
      </Container>
    </CloseableModal>
  );
}
