import { Container, Typography } from "@mui/material";
import WifiIcon from "@mui/icons-material/Wifi";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SmsIcon from "@mui/icons-material/Sms";
import { grey } from "@mui/material/colors";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import { useSharedContext } from "../../../SharedContext";

export default function PackageInfo({ variant, iconSize, gap, package_info, sx }) {
  const { t } = useSharedContext();

  return (
    package_info && (
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          "& > .MuiContainer-root": { px: "16px !important" },
          "& .infoContainer": { display: "flex", gap: { gap }, alignItems: "center" },
          "& .MuiSvgIcon-root": { fontSize: iconSize, color: grey[800] },
          ...sx,
        }}
        disableGutters
      >
        {"data" in package_info && (
          <Container>
            <Container className="infoContainer" disableGutters>
              <WifiIcon />
              <Typography variant={variant}>
                {t("data")}: <b>{package_info.data}</b>
              </Typography>
            </Container>
            {"data_sub" in package_info && <Typography className="remarkText">{package_info.sub_data}</Typography>}
          </Container>
        )}
        {"calls" in package_info && (
          <Container>
            <Container className="infoContainer" disableGutters>
              <LocalPhoneIcon />
              <Typography variant={variant}>
                {t("calls")}: <b>{package_info.calls}</b>
              </Typography>
            </Container>
            {"calls_sub" in package_info && <Typography className="remarkText">{package_info.calls_sub}</Typography>}
          </Container>
        )}
        {"sms" in package_info && (
          <Container>
            <Container className="infoContainer" disableGutters>
              <SmsIcon />
              <Typography variant={variant}>
                {t("sms")}: <b>{package_info.sms}</b>
              </Typography>
            </Container>
            {"sms_sub" in package_info && <Typography className="remarkText">{package_info.sms_sub}</Typography>}
          </Container>
        )}
        {"intl_calls" in package_info && (
          <Container>
            <Container className="infoContainer" disableGutters>
              <AirplanemodeActiveIcon />
              <Typography variant={variant}>
                {t("intlCalls")}: <b>{package_info.intl_calls}</b>
              </Typography>
            </Container>
            {"intl_calls_sub" in package_info && (
              <Typography className="remarkText">{package_info.intl_calls_sub}</Typography>
            )}
          </Container>
        )}
        <Container>
          <Container className="infoContainer" disableGutters>
            <CalendarMonthIcon />
            <Typography variant={variant}>
              {t("validity")}: <b>{package_info.validity}</b>
            </Typography>
          </Container>
          {"validity_sub" in package_info && (
            <Typography className="remarkText">{package_info.validity_sub}</Typography>
          )}
        </Container>
      </Container>
    )
  );
}
