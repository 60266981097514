import { Box, Button, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSharedContext } from "../../SharedContext";

export default function MenuDesktop({ navItems, handleMouseEnter, handleMouseLeave }) {
  const { isRTL, t, navigate } = useSharedContext();

  const handleClick = (navItem) => {
    if ("route" in navItem) navigate(navItem.route);
    else if ("module" in navItem) navItem.module();
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: "none", lg: "flex" },
        flexDirection: isRTL ? "row-reverse" : "unset",
        [isRTL ? "marginRight" : "marginLeft"]: 2,
      }}
    >
      {navItems.map((navItem, i) => (
        <Button
          key={navItem.key}
          sx={{
            color: "white",
            paddingX: 2,
            "&:hover": { bgcolor: "unset", color: "var(--text-hover-color)" },
            "&:focus": { outline: "none", oxShadow: "none", bgcolor: "unset" },
            display: "flex",
            flexDirection: isRTL ? "row" : "row-reverse",
          }}
          onClick={() => handleClick(navItem)}
          onMouseEnter={navItem.key === "packages" ? handleMouseEnter : null}
          onMouseLeave={navItem.key === "packages" ? () => handleMouseLeave(false) : null}
          disableRipple
        >
          {navItem.key === "packages" && <ArrowDropDownIcon sx={{ [isRTL ? "marginRight" : "marginLeft"]: "3px" }} />}
          <Typography>{t(navItem.key)}</Typography>
        </Button>
      ))}
    </Box>
  );
}
