import { Container, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { grey } from "@mui/material/colors";
import { useSharedContext } from "../../../SharedContext";

export default function SimCardInfo({ sx }) {
  const { isRTL } = useSharedContext();

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "3px",
        "& .MuiTypography-root": { textAlign: isRTL ? "right" : "left" },
        "& .MuiContainer-root": {
          display: "flex",
          gap: "7px",
        },
        "& .MuiSvgIcon-root": { color: grey[800], marginTop: "3px" },
        ...sx,
      }}
      disableGutters
    >
      <Container disableGutters>
        <LocalOfferIcon fontSize="small" />
        <Typography>
          <Trans i18nKey="simPrice" components={{ b: <b /> }} />
        </Typography>
      </Container>
      <Container disableGutters>
        <DeliveryDiningIcon fontSize="small" />
        <Typography>
          <Trans i18nKey="simDelivery" components={{ b: <b /> }} />
        </Typography>
      </Container>
      <Container disableGutters>
        <Inventory2Icon fontSize="small" />
        <Typography>
          <Trans i18nKey="simPackage" components={{ b: <b /> }} />
        </Typography>
      </Container>
    </Container>
  );
}
