import React from "react";
import { Container, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import ROUTES from "../../routes";
import PartnerButton from "../Common/PartnerButton";
import CloseableModal from "../Common/ClosableModal";
import SimCardInfo from "../Common/SimCard/SimCardInfo";
import { useSharedContext } from "../../SharedContext";
import Navigate from "../Common/Navigate";

export default function SimModal({ open, setOpen, name }) {
  const { t, navigate } = useSharedContext();

  return (
    <CloseableModal
      open={open}
      setOpen={setOpen}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "25px",
        pt: "40px",
        pb: "20px",
        px: { xs: "15px", sm: "30px" },
        outline: "none",
      }}
    >
      <Container disableGutters>
        <Typography variant="h5" sx={{ marginBottom: "3px" }}>
          <Trans i18nKey="simCard" values={{ operator_sim: t(`${name}_sim`) }} components={{ b: <b /> }} />
        </Typography>
        <SimCardInfo />
      </Container>
      <Navigate to={ROUTES.SIM_ORDER_PACKAGES.replace(":operator_name", name)}>
        <PartnerButton sx={{ width: "fit-content", marginX: "auto" }}>{t("continueToPackage")}</PartnerButton>
      </Navigate>
    </CloseableModal>
  );
}
