import { MenuItem, Typography } from "@mui/material";
import ROUTES from "../../routes";
import { useSharedContext } from "../../SharedContext";
import Navigate from "../Common/Navigate";

export default function SimPackagesMenuMobile({ handleCloseNavMenu, isOpen }) {
  const { isRTL, t, operators } = useSharedContext();

  return (
    <div style={{ display: isOpen ? "block" : "none", paddingBottom: "6px" }}>
      {operators.map((operator) => (
        <Navigate key={operator.name} to={ROUTES.TOP_UP_PACKAGES.replace(":operator_name", operator.name)}>
          <MenuItem
            sx={{
              justifyContent: isRTL ? "end" : "start",
              flexDirection: isRTL ? "row" : "row-reverse",
              gap: "8px",
              alignItems: "center",
              "&:not(:last-child)::before": {
                content: '""',
                position: "absolute",
                bottom: 0,
                left: "16px",
                right: "16px",
                borderBottom: "3px dotted black",
              },
              "&:hover .MuiTypography-root": {
                color: "#2ED5C4",
              },
            }}
            onClick={() => handleCloseNavMenu()}
          >
            <Typography sx={{ direction: isRTL ? "rtl" : "ltr" }}>{t(operator.name)}</Typography>
            <img
              style={{ width: "20px", height: "20px", borderRadius: "50px", marginTop: "1px" }}
              alt={t(operator.name)}
              src={`/images/operators/with_background/${operator.name}.webp`}
            />
          </MenuItem>
        </Navigate>
      ))}
    </div>
  );
}
