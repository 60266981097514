import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useSharedContext } from "./SharedContext";
import PageNotFound from "./components/Common/PageNotFound";

export default function LanguageRouteHandler() {
  const { i18n } = useSharedContext();
  const { lang } = useParams();
  const goTo = useNavigate();
  const isSupportedLang = Object.keys(i18n.options.resources).includes(lang);

  useEffect(() => {
    if (lang == undefined) {
      const currUrl = window.location.pathname;
      const newUrl = `/${i18n.language}` + (currUrl !== "/" ? currUrl : "");
      goTo(newUrl);
    } else if (isSupportedLang && lang !== i18n.language) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  if (lang != undefined && !isSupportedLang) {
    return <PageNotFound />;
  }

  return <Outlet />;
}
