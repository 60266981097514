const ROUTES = {
  HOME: "/:lang?",
  TOP_UP_PACKAGES: "/:lang/topup/packages/:operator_name",
  SIM_ORDER_PACKAGES: "/:lang/simorder/packages/:operator_name",
  CONTACT: "/:lang/contact",
  CART: "/:lang/cart",
  ACCESSIBILITY: "/:lang/accessibility",
  PRIVACY: "/:lang/privacy",
  TERMS: "/:lang/terms",
};

export default ROUTES;
