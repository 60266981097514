import { Container, Grid, TextField, Typography } from "@mui/material";
import MailLockIcon from "@mui/icons-material/MailLock";
import PartnerButton from "../Common/PartnerButton";
import { useState } from "react";
import { useSharedContext } from "../../SharedContext";
import API from "../../API";

export default function Form({ setConfirmation }) {
  const { isRTL, t, i18n } = useSharedContext();
  const [isSending, setIsSending] = useState(false);

  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    title: "",
    applicationContent: "",
  });

  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    applicationContent: "",
  });

  const validatePhoneNumber = (number) => {
    const regex = /^(0|972|\+972)?5[0-9]{8}$/;
    return regex.test(number);
  };

  const validateField = (name, value) => {
    let error = "";
    if (name === "fullName" && !value) error = t("requiredField");
    if (name === "applicationContent" && !value) error = t("requiredField");
    if (name === "phoneNumber" && value !== "" && !validatePhoneNumber(value)) error = t("phoneNumberNotValid");
    if (name === "email") {
      if (!value) error = t("requiredField");
      else if (!/\S+@\S+\.\S+/.test(value)) error = t("emailNotValid");
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const cleanedValue = name !== "phoneNumber" ? value : value.replace(/[^\d+]/g, "");
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: cleanedValue,
    }));
    if (errors[name]) {
      const fieldError = validateField(name, cleanedValue);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldError,
      }));
    }
  };

  const validateFields = () => {
    let newErrors = {};
    Object.keys(formValues).forEach((name) => {
      const error = validateField(name, formValues[name]);
      if (error) newErrors[name] = error;
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    setIsSending(true);
    e.preventDefault();
    if (validateFields()) {
      const clearedFormValues = Object.fromEntries(
        Object.entries(formValues).filter(([_, value]) => value.trim() !== "")
      );
      clearedFormValues["language"] = i18n.language;
      const res = await API.contactUs(clearedFormValues);
      if (res != null) {
        const app_id = res["app_id"];
        setConfirmation(app_id);
      }
    }
    setIsSending(false);
  };

  return (
    <Container
      sx={{
        bgcolor: "var(--package-card-color)",
        borderRadius: "30px",
        paddingY: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Container sx={{ display: "flex", gap: "7px", alignItems: "center" }} disableGutters>
        <MailLockIcon />
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {t("onlineApplication")}
        </Typography>
      </Container>
      <Grid
        sx={{
          "& .MuiTypography-root": { marginBottom: "5px" },
          "& .MuiOutlinedInput-root": { bgcolor: "white", borderRadius: "6px" },
          "& .MuiFormHelperText-root": { marginLeft: isRTL ? "auto" : "unset" },
        }}
        container
        spacing={3}
        component="form"
        onSubmit={handleSubmit}
      >
        <Grid item xs={12} sm={6}>
          <Typography>{t("fullName")}*</Typography>
          <TextField
            name="fullName"
            placeholder={t("enterFullName")}
            fullWidth
            value={formValues.fullName}
            onChange={handleChange}
            error={Boolean(errors.fullName)}
            helperText={errors.fullName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>{t("email")}*</Typography>
          <TextField
            name="email"
            placeholder={t("enterEmail")}
            fullWidth
            value={formValues.email}
            onChange={handleChange}
            error={Boolean(errors.email)}
            helperText={errors.email}
            sx={{ direction: "ltr" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>
            {t("contactPhoneNumber")} ({t("notRequired")})
          </Typography>
          <TextField
            name="phoneNumber"
            placeholder={t("enterPhoneNumber")}
            fullWidth
            value={formValues.phoneNumber}
            onChange={handleChange}
            error={Boolean(errors.phoneNumber)}
            helperText={errors.phoneNumber}
            sx={{ direction: "ltr" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>
            {t("applicationTitle")} ({t("notRequired")})
          </Typography>
          <TextField
            name="title"
            placeholder={t("enterTitle")}
            fullWidth
            value={formValues.title}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>{t("applicationContent")}*</Typography>
          <TextField
            name="applicationContent"
            placeholder={t("applicationMsg")}
            multiline
            rows={8}
            fullWidth
            value={formValues.applicationContent}
            onChange={handleChange}
            error={Boolean(errors.applicationContent)}
            helperText={errors.applicationContent}
          />
        </Grid>
        <PartnerButton sx={{ width: "fit-content", mt: "30px", ml: "24px" }} loading={isSending} type="submit">
          {t("submitApplication")}
        </PartnerButton>
      </Grid>
    </Container>
  );
}
