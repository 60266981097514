import SimCardIcon from "@mui/icons-material/SimCard";
import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function SimCard({ name, onClick, color, className, sx }) {
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        width: "fit-content",
        marginBottom: "10px",
        ...sx,
      }}
      className={className}
      onClick={onClick}
      disableGutters
    >
      <Container
        sx={{ display: "flex", flexDirection: "row-reverse", position: "relative", width: "fit-content" }}
        disableGutters
      >
        <Container
          sx={{
            width: "50px",
            height: "40px",
            bgcolor: "var(--gold-color)",
            position: "absolute",
            left: "25px",
            top: "40px",
            marginLeft: "unset",
          }}
          disableGutters
        />
        <SimCardIcon sx={{ fontSize: "6rem", transform: "scaleX(-1)", color: color }} />
        <img
          src={`/images/operators/with_background/${name}.webp`}
          alt={t(name)}
          style={{
            width: "35px",
            height: "35px",
            position: "absolute",
            left: "30px",
            top: "8px",
            borderTopRightRadius: "18px",
          }}
        />
      </Container>
      <Typography align="center" sx={{ fontWeight: "bold" }}>
        {t(name)}
      </Typography>
    </Container>
  );
}
