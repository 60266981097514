import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1536,
    },
  },
});

const headerFontSize = {
  h2: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "3.5rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "4.25rem",
    },
  },
  h4: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "2.125rem",
    },
  },
  h5: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.35rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.5rem",
    },
  },
  h6: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.15rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.25rem",
    },
  },
};

theme = createTheme({
  ...theme,
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "xl",
      },
      variants: [
        {
          props: { maxWidth: "sm" },
          style: {
            maxWidth: `${theme.breakpoints.values.sm}px !important`,
          },
        },
        {
          props: { maxWidth: "md" },
          style: {
            maxWidth: `${theme.breakpoints.values.md}px !important`,
          },
        },
        {
          props: { maxWidth: "lg" },
          style: {
            maxWidth: `${theme.breakpoints.values.lg}px !important`,
          },
        },
        {
          props: { maxWidth: "xl" },
          style: {
            maxWidth: `${theme.breakpoints.values.xl}px !important`,
          },
        },
        {
          props: { maxWidth: "xxl" },
          style: {
            maxWidth: `${theme.breakpoints.values.xxl}px !important`,
          },
        },
      ],
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeH2: headerFontSize.h2,
        fontSizeH4: headerFontSize.h4,
        fontSizeH5: headerFontSize.h5,
      },
    },
  },
  typography: {
    ...headerFontSize,
    fontFamily: `"Open Sans", "Cairo", sans-serif`,
  },
});

export default theme;
