import * as React from "react";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SimPackagesMenuMobile from "./SimPackagesMenuMobile";
import { useSharedContext } from "../../SharedContext";
import { useState } from "react";

export default function MenuMobile({ navItems }) {
  const { isRTL, t, navigate } = useSharedContext();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [isSimPackagesMenuOpen, setIsSimPackagesMenuOpen] = useState(false);

  const handleClick = (navItem) => {
    if (navItem.key === "packages") {
      setIsSimPackagesMenuOpen(!isSimPackagesMenuOpen);
    } else {
      handleCloseNavMenu();
      if ("route" in navItem) navigate(navItem.route);
      else if ("module" in navItem) navItem.module();
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleExited = () => {
    if (anchorElNav == null) setIsSimPackagesMenuOpen(false);
  };

  return (
    <Box sx={{ display: { xs: "flex", lg: "none" }, flexGrow: 1, flexDirection: isRTL ? "row-reverse" : "row" }}>
      <IconButton
        size="small"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        onAnimationEnd={handleExited}
        sx={{
          display: { xs: "block", lg: "none" },
          top: "8px",
        }}
      >
        {navItems.map((navItem, i) => (
          <Box key={navItem.key}>
            <MenuItem
              sx={{ justifyContent: isRTL ? "end" : "start", flexDirection: isRTL ? "row" : "row-reverse" }}
              onClick={() => handleClick(navItem)}
            >
              {navItem.key === "packages" && (isSimPackagesMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
              <Typography>{t(navItem.key)}</Typography>
            </MenuItem>
            {navItem.key === "packages" && (
              <SimPackagesMenuMobile handleCloseNavMenu={handleCloseNavMenu} isOpen={isSimPackagesMenuOpen} />
            )}
          </Box>
        ))}
      </Menu>
    </Box>
  );
}
