import { Box, Container } from "@mui/material";
import ROUTES from "../../routes";
import Recommended from "../Common/Recommended";
import { useSharedContext } from "../../SharedContext";
import Navigate from "../Common/Navigate";

export default function Operators({ sx }) {
  const { t, isRTL, operators } = useSharedContext();

  return (
    <Container
      sx={{
        width: { xs: "100%", lg: "433px" },
        minWidth: { lg: "433px" },
        display: "flex",
        flexWrap: "wrap",
        gap: "5px",
        justifyContent: "center",
        direction: isRTL ? "rtl" : "ltr",
        ...sx,
      }}
    >
      {operators.map((operator) => (
        <Navigate key={operator.name} to={ROUTES.TOP_UP_PACKAGES.replace(":operator_name", operator.name)}>
          <Box
            component="div"
            sx={{
              position: "relative",
              width: { xs: "30%", sm: "125px" },
              minWidth: "75px",
              maxWidth: "125px",
              aspectRatio: "1 / 1",
              borderRadius: "10px",
              cursor: "pointer",
              transitionDuration: ".2s",
              transitionProperty: "transform",
              "&:hover": { transform: "unset" },
              "@media (hover: hover) and (pointer: fine)": {
                "&:hover": { transform: "scale(1.1) rotate(3deg)" },
              },
            }}
          >
            <img
              src={`/images/operators/with_background/${operator.name}.webp`}
              alt={t(operator.name)}
              style={{
                width: "100%",
                borderRadius: "10px",
                boxShadow: "0px 0px 12px 0px rgba(0,0,0,0.5)",
              }}
            />
            {["partner", "012mobile"].includes(operator.name) && <Recommended text={t("top")} />}
          </Box>
        </Navigate>
      ))}
    </Container>
  );
}
