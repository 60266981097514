import { Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CenteredContainer from "./CenteredContainer";
import { useSharedContext } from "../../SharedContext";

export default function AutoLoadingParagraph() {
  const { isRTL, t } = useSharedContext();

  return (
    <CenteredContainer sx={{ gap: "5px", width: "fit-content" }} disableGutters>
      <CircleIcon
        sx={{
          color: "var(--online-color)",
          marginTop: isRTL ? "2px" : "0px",
          fontSize: { xs: "1em", sm: "1.15em", xl: "1.25em" },
        }}
      />
      <Typography variant="h6">{t("autoLoading")}</Typography>
    </CenteredContainer>
  );
}
