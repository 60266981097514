import CenteredContainer from "../Common/CenteredContainer";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import { Link, Typography } from "@mui/material";
import { useSharedContext } from "../../SharedContext";
import { Trans } from "react-i18next";
import ROUTES from "../../routes";
import Navigate from "../Common/Navigate";

export default function ContactConfirmation({ app_id }) {
  const { t, navigate, isRTL } = useSharedContext();

  return (
    <CenteredContainer sx={{ height: "100vh", flexDirection: "column" }} maxWidth={false}>
      <MarkChatReadIcon sx={{ fontSize: "7rem", color: "var(--online-color)" }} />
      <Typography align="center" sx={{ fontSize: "2.25rem" }}>
        {t("thankYou")}
      </Typography>
      <Typography align={isRTL ? "right" : "left"} sx={{ fontSize: "1.25rem" }}>
        {t("applicationReceived")}
        <br />
        <Trans i18nKey="appIdSentToEmail" values={{ app_id: app_id }} components={{ b: <b />, br: <br /> }} />
        <br />
      </Typography>
      <Typography align="center" sx={{ fontSize: "1.25rem" }}>
        <Navigate to={ROUTES.HOME} withDecoration>
          {t("backToHomePage")}
        </Navigate>
      </Typography>
    </CenteredContainer>
  );
}
