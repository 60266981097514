import { Container, Typography } from "@mui/material";
import theme from "../../theme";

export default function Header({ image, title, subtitle, autoLoadingParagraph }) {
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "20px",
        [theme.breakpoints.down("sm")]: { padding: 0 },
      }}
      maxWidth="xxl"
    >
      <Container
        sx={{ width: "125px", display: { xs: "none", md: "flex" }, justifyContent: "center" }}
        maxWidth={false}
      >
        {image}
      </Container>
      <Container sx={{ [theme.breakpoints.down("sm")]: { padding: 0 } }} maxWidth={false}>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
          {subtitle}
        </Typography>
        {autoLoadingParagraph}
      </Container>
    </Container>
  );
}
