import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar/App";
import HomePage from "./components/HomePage/App";
import SimPackages from "./components/SimPackages/App";
import Contact from "./components/Contact/App";
import ROUTES from "./routes";
import { Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import AutoScrollToTop from "./components/Common/AutoScrollToTop";
import Cart from "./components/Cart/App";
import CartModal from "./components/Common/CartModal";
import { SharedContextProvider } from "./SharedContext";
import PageNotFound from "./components/Common/PageNotFound";
import { PopUp } from "./components/Common/PopUp";
import Footer from "./components/Footer/App";
import StaticPage from "./components/Footer/StaticPage";
import WhatsappWidget from "./components/Common/WhatsappWidget";
import { HelmetProvider } from "react-helmet-async";
import HreflangTags from "./HreflangTags";
import LanguageRouteHandler from "./LanguageRouteHandler";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <Router>
          <SharedContextProvider>
            <HreflangTags />
            <Container sx={{ minHeight: "CALC(100vh + 50px)", position: "relative" }} maxWidth={false} disableGutters>
              <CartModal />
              <NavBar />
              <PopUp sx={{ mt: "62px" }} />
              <Routes>
                <Route element={<LanguageRouteHandler />}>
                  <Route path={ROUTES.HOME} element={<HomePage />} />
                  <Route path={ROUTES.TOP_UP_PACKAGES} element={<SimPackages isTopUp={true} />} />
                  <Route path={ROUTES.SIM_ORDER_PACKAGES} element={<SimPackages isTopUp={false} />} />
                  <Route path={ROUTES.CONTACT} element={<Contact />} />
                  <Route path={ROUTES.CART} element={<Cart />} />
                  <Route path={ROUTES.ACCESSIBILITY} element={<StaticPage page={"accessibilityStatement"} />} />
                  <Route path={ROUTES.PRIVACY} element={<StaticPage page={"privacyPolicy"} />} />
                  <Route path={ROUTES.TERMS} element={<StaticPage page={"termsOfUse"} />} />
                  <Route path=":lang?/*" element={<PageNotFound />} />
                </Route>
              </Routes>
              <Footer />
              <WhatsappWidget />
            </Container>
            <AutoScrollToTop />
          </SharedContextProvider>
        </Router>
      </HelmetProvider>
    </ThemeProvider>
  );
}
