import { Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export default function PartnerButton({ children, onClick, type, loading, sx }) {
  return (
    <LoadingButton
      variant="contained"
      onClick={onClick}
      type={type}
      loading={loading}
      sx={{
        marginTop: "10px",
        px: 4,
        bgcolor: "var(--partner-color)",
        color: "black",
        "&:hover": { bgcolor: "var(--partner-color)" },
        "@media (hover: hover) and (pointer: fine)": {
          "&:hover": { bgcolor: "black", color: "white" },
        },
        ...sx,
      }}
    >
      <Typography variant="h6" fontWeight="bold">
        {children}
      </Typography>
    </LoadingButton>
  );
}
