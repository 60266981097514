import { Container, Link, Typography } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import { useSharedContext } from "../../SharedContext";
import CenteredContainer from "../Common/CenteredContainer";
import { grey } from "@mui/material/colors";
import Form from "./Form";
import ContactConfirmation from "./ContactConfirmation";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

export default function Contact() {
  const { isRTL, t } = useSharedContext();
  const [confirmation, setConfirmation] = useState();
  const infoTypographyStyle = { display: "flex", maxWidth: "100%", justifyContent: "space-between" };

  if (confirmation != null) return <ContactConfirmation app_id={confirmation} />;

  return (
    <Container
      sx={{
        pt: { xs: "80px", lg: "120px" },
        pb: "68px",
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      <Helmet>
        <title>{t("titles.contactUsPage")}</title>
      </Helmet>
      <Container
        sx={{
          mb: "20px",
          "& .MuiContainer-root": { p: 0, display: "flex", alignItems: "center", gap: "7px" },
          "& .MuiSvgIcon-root": { p: "3px", borderRadius: "50%" },
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          {t("contact_us")}
        </Typography>
        <Typography variant="h6" sx={{ mb: "10px" }}>
          {t("contactUsSubtitle")}
        </Typography>
        <Container sx={{ mb: { xs: "5px", sm: "0px" } }}>
          <WhatsAppIcon fontSize="small" sx={{ color: "white", bgcolor: "#5DC861" }} />
          <Typography sx={{ width: "390px", ...infoTypographyStyle }} variant="h6">
            {t("throughWhatsapp")}:{" "}
            <Link target="_blank" rel="noopener" href="https://wa.me/972548341010" underline="none">
              054-8341010
            </Link>
          </Typography>
        </Container>
        <Container sx={{ mb: "10px" }}>
          <EmailIcon fontSize="small" sx={{ color: "white", bgcolor: grey[500] }} />
          <Typography sx={{ width: "390px", ...infoTypographyStyle }} variant="h6">
            {t("throughMail")}: <span>contact@zolsim.co.il</span>
          </Typography>
        </Container>
        <Container
          sx={{
            display: "block !important",
            "& .MuiTypography-root": {
              lineHeight: "1.4",
              width: "250px",
              ...infoTypographyStyle,
              "& span": {
                width: "120px",
              },
            },
          }}
        >
          <Typography variant="h6">{t("workingHours")}:</Typography>
          <Typography variant="h6">
            {t("sunToThu")}: <span>09:00 - 19:00</span>
          </Typography>
          <Typography variant="h6" sx={{ width: "300px" }}>
            {t("fri")}: <span>09:00 - 16:00</span>
          </Typography>
          <Typography variant="h6">
            {t("sat")}: <span>{t("closed")}</span>
          </Typography>
        </Container>
      </Container>
      <CenteredContainer sx={{ borderTop: "2px solid black", position: "relative", height: "20px", mb: "5px" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", bgcolor: "white", position: "absolute", top: "-18px", px: "7px" }}
        >
          {t("or")}
        </Typography>
      </CenteredContainer>
      <Form setConfirmation={setConfirmation} />
    </Container>
  );
}
