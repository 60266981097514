import { Container, Typography } from "@mui/material";
import { useSharedContext } from "../../SharedContext";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";

export default function Recommended({ text }) {
  const { isRTL } = useSharedContext();
  return (
    <Container
      sx={{
        display: "flex",
        bgcolor: "var(--red-color)",
        color: "white",
        pt: "2px",
        pb: "3px",
        pr: isRTL ? "8px" : "4px",
        pl: isRTL ? "4px" : "8px",
        borderRadius: "30px",
        position: "absolute",
        top: "-14px",
        left: "50%",
        transform: "translate(-50%, 0%)",
        width: "fit-content",
        zIndex: 1,
        direction: isRTL ? "rtl" : "ltr",
      }}
      disableGutters
    >
      <Typography variant="body2">{text}</Typography>
      <LocalFireDepartmentIcon sx={{ color: "var(--gold-color)", fontSize: "1.25rem" }} />
    </Container>
  );
}
