import axios from "axios";
import { showPopUp } from "./components/Common/PopUp";

let baseUrl = window.location.origin;
if (baseUrl.includes("localhost")) {
  baseUrl = "http://127.0.0.1:5000";
}

const showErrorPopUp = (error) => {
  showPopUp("error", "An error has occured. Please reload the page");
};

const getRecommendedPackages = async (language) => {
  try {
    const res = await axios.get(`${baseUrl}/service/getRecommendedPackages?language=${language}`);
    return res.data;
  } catch (error) {
    showErrorPopUp(error);
    return null;
  }
};

const getOperatorPackages = async (operator, language) => {
  try {
    const res = await axios.get(`${baseUrl}/service/getOperatorPackages?operator=${operator}&language=${language}`);
    return res.data;
  } catch (error) {
    showErrorPopUp(error);
    return null;
  }
};

const getPackagesTitleAndPrice = async (packages_id, language) => {
  const data = { packages_id: packages_id, language: language };
  try {
    const res = await axios.post(`${baseUrl}/service/getPackagesTitleAndPrice`, data);
    return res.data;
  } catch (error) {
    showErrorPopUp(error);
    return null;
  }
};

const contactUs = async (data) => {
  try {
    const res = await axios.post(`${baseUrl}/service/contactUs`, data);
    return res.data;
  } catch (error) {
    showErrorPopUp(error);
    return null;
  }
};

const API = {
  getRecommendedPackages,
  getOperatorPackages,
  getPackagesTitleAndPrice,
  contactUs,
};

export default API;
