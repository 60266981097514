import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Container, Typography } from "@mui/material";
import { useSharedContext } from "../../SharedContext";
import Navigate from "../Common/Navigate";
import ROUTES from "../../routes";

export default function ShoppingCart() {
  const { cartItems } = useSharedContext();

  return (
    <Container sx={{ display: "flex", width: "fit-content", cursor: "pointer", position: "relative" }} disableGutters>
      <Navigate to={ROUTES.CART}>
        <ShoppingCartIcon />
        <Container
          sx={{
            bgcolor: "var(--gold-color)",
            color: "black",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "15px",
            height: "15px",
            width: "fit-content",
            position: "absolute",
            transform: "scale(1) translate(-50%, -50%)",
            left: "3px",
            top: "1px",
            padding: "0px 4px",
            borderRadius: "10px",
          }}
          disableGutters
        >
          <Typography
            sx={{
              fontSize: "10px",
              fontWeight: "bold",
              lineHeight: "0.7",
              whiteSpace: "nowrap",
            }}
          >
            {cartItems.length}
          </Typography>
        </Container>
      </Navigate>
    </Container>
  );
}
