import Container from "@mui/material/Container";

export default function CenteredContainer({ sx, maxWidth, disableGutters, children }) {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginX: "unset",
        ...sx,
      }}
      maxWidth={maxWidth}
      disableGutters={disableGutters}
    >
      {children}
    </Container>
  );
}
