import React from "react";
import { Container, Typography } from "@mui/material";
import ROUTES from "../../routes";
import { useSharedContext } from "../../SharedContext";
import Navigate from "../Common/Navigate";

export default function SimPackagesMenu({ showDropdown, handleMouseEnter, handleMouseLeave }) {
  const { isRTL, t, operators } = useSharedContext();

  return (
    <Container
      sx={{
        position: "fixed",
        top: "60px",
        display: showDropdown ? "block" : "none",
        zIndex: 3,
      }}
      maxWidth={false}
      disableGutters
    >
      <Container maxWidth="xxl">
        <Container
          sx={{
            bgcolor: "var(--navbar-color)",
            backdropFilter: "blur(5px)",
            width: `CALC(125px*${operators.length})`,
            maxWidth: "100% !important",
            marginRight: 0,
            marginLeft: isRTL ? "auto" : 0,
            borderRadius: "10px",
            display: "flex",
            flexDirection: isRTL ? "row-reverse" : "row",
            overflowX: "auto",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={() => handleMouseLeave(false)}
          disableGutters
        >
          {operators.map((operator) => (
            <Navigate
              key={operator.name}
              to={ROUTES.TOP_UP_PACKAGES.replace(":operator_name", operator.name)}
              sx={{
                "&:last-child .MuiContainer-root": {
                  border: "none !important",
                },
              }}
            >
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: "125px",
                  height: "100px",
                  [isRTL ? "borderLeft" : "borderRight"]: "3px dotted white !important",
                  "&:last-child": {
                    border: "none",
                  },
                  "&:hover .MuiTypography-root": {
                    color: "var(--partner-color)",
                  },
                }}
                onClick={() => handleMouseLeave(true)}
                maxWidth={false}
                disableGutters
              >
                <img
                  style={{ width: "50px", height: "50px", borderRadius: "50px" }}
                  alt={t(operator.name)}
                  src={`/images/operators/with_background/${operator.name}.webp`}
                />
                <Typography sx={{ color: "white", direction: isRTL ? "rtl" : "ltr" }}>{t(operator.name)}</Typography>
              </Container>
            </Navigate>
          ))}
        </Container>
      </Container>
    </Container>
  );
}
