import { useRef } from "react";
import MainSection from "./MainSection";
import TopPackages from "./TopPackages";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export default function HomePage() {
  const { t } = useTranslation();
  const topPackagesRef = useRef(null);

  return (
    <Container maxWidth={false} disableGutters>
      <Helmet>
        <title>{t("titles.homePage")}</title>
      </Helmet>
      <MainSection ref={topPackagesRef} />
      <TopPackages ref={topPackagesRef} />
    </Container>
  );
}
