import SimCardIcon from "@mui/icons-material/SimCard";
import { Box, Typography } from "@mui/material";
import ROUTES from "../../routes";
import Navigate from "../Common/Navigate";

export default function Logo({ sx }) {
  return (
    <Navigate to={ROUTES.HOME}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          ...sx,
        }}
      >
        <SimCardIcon sx={{ display: "flex", mr: 1, color: "var(--partner-color)" }} />
        <Typography
          variant="h6"
          noWrap
          sx={{
            display: "flex",
            flexGrow: 1,
            fontFamily: "Spline Sans Mono",
            fontWeight: 400,
            letterSpacing: ".1rem",
          }}
        >
          Zol
          <span style={{ color: "var(--partner-color)" }}>SIM</span>
        </Typography>
      </Box>
    </Navigate>
  );
}
