import { Container, Typography } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WazeIcon from "./WazeIcon";
import { useTranslation } from "react-i18next";
import { grey } from "@mui/material/colors";

export default function UnlimitedSurfing({ sx }) {
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, 0%)",
        top: "-14px",
        width: "max-content",
        bgcolor: grey[900],
        p: "3px 6px",
        gap: "3px",
        borderRadius: "30px",
        "& svg": {
          color: "white",
          padding: "4px",
          borderRadius: "50%",
          fontSize: "0.75rem",
        },
        ...sx,
      }}
      disableGutters
    >
      <Typography variant="body2" sx={{ color: "white", fontWeight: "500" }}>
        {t("unlimited_surfing")}
      </Typography>
      <InstagramIcon
        sx={{
          background: "linear-gradient(45deg, #833AB4, #FD1D1D, #F77737)",
        }}
      />
      <FacebookIcon sx={{ bgcolor: "#2E64BF" }} />
      <WazeIcon sx={{ backgroundColor: "#57C7F4", padding: "3px" }} />
      <WhatsAppIcon sx={{ bgcolor: "#5DC861" }} />
    </Container>
  );
}
