import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

export default function HreflangTags() {
  const { pathname } = useLocation();

  return (
    <Helmet>
      <link rel="alternate" href={`https://zolsim.co.il/ar${pathname.slice(3)}`} hreflang="ar" />
      <link rel="alternate" href={`https://zolsim.co.il/ru${pathname.slice(3)}`} hreflang="ru" />
      <link rel="alternate" href={`https://zolsim.co.il/en${pathname.slice(3)}`} hreflang="en" />
      <link rel="alternate" href={`https://zolsim.co.il/he${pathname.slice(3)}`} hreflang="x-default" />
    </Helmet>
  );
}
