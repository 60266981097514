import React, { useState } from "react";
import { Container, Typography } from "@mui/material";
import ROUTES from "../../routes";
import CloseableModal from "../Common/ClosableModal";
import SimCardInfo from "../Common/SimCard/SimCardInfo";
import CenteredContainer from "../Common/CenteredContainer";
import SimCard from "../Common/SimCard/SimCard";
import { useSharedContext } from "../../SharedContext";
import PartnerButton from "../Common/PartnerButton";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import Navigate from "../Common/Navigate";

export default function PurchaseSimModule({ open, setOpen }) {
  const { isRTL, t, operators } = useSharedContext();
  const [isFirstStage, setIsFirstStage] = useState(true);

  const onModalClose = () => {
    setOpen(false);
    setIsFirstStage(true);
  };

  return (
    <CloseableModal open={open} setOpen={onModalClose} sx={{ px: { xs: "15px", sm: "30px" } }}>
      {isFirstStage ? (
        <Container sx={{ display: "flex", flexDirection: "column" }} disableGutters>
          <Typography variant="h5" sx={{ marginBottom: "3px", fontWeight: "bold" }}>
            {t("homeDelivery")}
          </Typography>
          <SimCardInfo sx={{ mb: "15px" }} />
          <PartnerButton
            sx={{ mx: "auto", [isRTL ? "paddingLeft" : "paddingRight"]: "24px" }}
            onClick={() => setIsFirstStage(false)}
          >
            <Container sx={{ display: "flex", alignItems: "center", gap: "3px" }} disableGutters>
              {t("continueToOperator")}
              <KeyboardDoubleArrowLeftIcon
                sx={{
                  fontSize: { sm: "1.65rem", xs: "1.35rem" },
                  transform: !isRTL ? "rotate(180deg)" : "unset",
                  mt: "1px",
                }}
              />
            </Container>
          </PartnerButton>
        </Container>
      ) : (
        <>
          <Typography variant="h5" sx={{ marginBottom: "15px", fontWeight: "bold" }}>
            {t("chooseOperator")}
          </Typography>
          <CenteredContainer sx={{ flexWrap: "wrap" }}>
            {operators.slice(0, -3).map((operator) => (
              <Navigate key={operator.name} to={ROUTES.SIM_ORDER_PACKAGES.replace(":operator_name", operator.name)}>
                <SimCard
                  key={operator.name}
                  name={operator.name}
                  color={operator.logo_bg_color}
                  onClick={() => onModalClose()}
                  className="rotateAnimation"
                />
              </Navigate>
            ))}
          </CenteredContainer>
        </>
      )}
    </CloseableModal>
  );
}
