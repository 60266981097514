import React from "react";
import { Typography } from "@mui/material";
import Operators from "./Operators";
import CloseableModal from "../Common/ClosableModal";
import { useSharedContext } from "../../SharedContext";

export default function OperatorsModal({ open, setOpen }) {
  const { isRTL, t } = useSharedContext();

  return (
    <CloseableModal open={open} setOpen={setOpen}>
      <Typography align={isRTL ? "right" : "left"} variant="h4" sx={{ paddingBottom: "20px", fontWeight: "bold" }}>
        {t("chooseOperator")}
      </Typography>
      <Operators sx={{ padding: 0 }} />
    </CloseableModal>
  );
}
