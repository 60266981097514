import { Button, Container, Typography } from "@mui/material";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { forwardRef, useState } from "react";
import OperatorsModal from "./OperatorsModal";
import Operators from "./Operators";
import AutoLoadingParagraph from "../Common/AutoLoadingParagraph";
import PartnerButton from "../Common/PartnerButton";
import { useSharedContext } from "../../SharedContext";

function MainSection({}, ref) {
  const { isRTL, t, i18n } = useSharedContext();
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const onClickTopPackages = () => {
    if (ref && ref.current) ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Container
      sx={{
        position: "relative",
        minHeight: "100svh",
        display: "flex",
        flexDirection: "column",
        backgroundImage: {
          xs: "url(/images/backgrounds/background-mobile.webp)",
          md: "url(/images/backgrounds/background3.webp)",
        },
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        pt: { xs: "80px", lg: "120px" },
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        },
      }}
      maxWidth={false}
      disableGutters
    >
      <OperatorsModal open={open} setOpen={setOpen} />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          alignItems: "center",
          height: "100%",
          paddingBottom: "24px",
          position: "relative",
          zIndex: 2,
        }}
        maxWidth={false}
        disableGutters
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", lg: isRTL ? "row" : "row-reverse" },
            justifyContent: "space-around",
          }}
          maxWidth="xxl"
          disableGutters
        >
          <Operators sx={{ mb: "16px" }} />
          <Container
            align={isRTL ? "right" : "left"}
            sx={{
              display: { xs: "flex", lg: "block" },
              color: "white",
              width: "auto",
              marginBottom: "20px",
              direction: isRTL ? "rtl" : "ltr",
              flexDirection: "column",
            }}
          >
            <Typography variant="h4">{t("topUpWebsite")}</Typography>
            <Typography
              variant="h2"
              sx={{ fontWeight: "bold", display: ["en", "ru"].includes(i18n.language) ? "grid" : "unset" }}
            >
              <span style={{ color: "var(--gold-color)" }}>{t("cheapest")}</span>{" "}
              <span style={{ whiteSpace: "nowrap" }}>{t("inIsrael")}</span>
            </Typography>
            <AutoLoadingParagraph />
            <PartnerButton sx={{ mb: "10px" }} onClick={openModal}>
              {t("instantCharge")}
            </PartnerButton>
          </Container>
        </Container>
        <Button
          variant="contained"
          sx={{
            [isRTL ? "paddingRight" : "paddingLeft"]: "32px",
            bgcolor: "var(--gold-color)",
            color: "black",
            marginTop: "auto",
            alignItems: "center",
            direction: isRTL ? "rtl" : "ltr",
            "&:hover": { bgcolor: "var(--gold-color)" },
            "@media (hover: hover) and (pointer: fine)": {
              "&:hover": { bgcolor: "black", color: "white" },
            },
          }}
          onClick={onClickTopPackages}
        >
          <LocalFireDepartmentIcon sx={{ [isRTL ? "marginLeft" : "marginRight"]: "2px" }} />
          <Typography variant="h6" fontWeight="bold" sx={{ [isRTL ? "marginLeft" : "marginRight"]: "20px" }}>
            {t("topPackagesButton")}
          </Typography>
          <KeyboardDoubleArrowDownIcon sx={{ marginTop: isRTL ? "1px" : "0px" }} />
        </Button>
      </Container>
    </Container>
  );
}

export default forwardRef(MainSection);
