import { Box, Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import Header from "./Header";
import PackageCardHorizontal from "../Common/PackageCard/PackageCardHorizontal";
import PackageCardVertical from "../Common/PackageCard/PackageCardVertical";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { grey } from "@mui/material/colors";
import { Trans } from "react-i18next";
import AutoLoadingParagraph from "../Common/AutoLoadingParagraph";
import SimCard from "../Common/SimCard/SimCard";
import { useSharedContext } from "../../SharedContext";
import PageNotFound from "../Common/PageNotFound";
import React, { useEffect, useLayoutEffect, useState } from "react";
import API from "../../API";
import LoadingSpinner from "../Common/LoadingSpinner";
import CenteredContainer from "../Common/CenteredContainer";
import { Helmet } from "react-helmet-async";

export default function SimPackages({ isTopUp }) {
  const { operator_name } = useParams();
  const { isLoading, setIsLoading, isRTL, t, i18n, operators } = useSharedContext();
  const [packages, setPackages] = useState();
  const isValidateOperatorName = operators.find((op) => op.name === operator_name);

  const getOperatorPackages = async () => {
    if (isValidateOperatorName) {
      const operator_packages = await API.getOperatorPackages(operator_name, i18n.language);
      if (operator_packages != null) {
        setPackages(operator_packages);
        setIsLoading(false);
      }
    }
  };

  useLayoutEffect(() => {
    setIsLoading(true);
  }, [operator_name, isTopUp, i18n.language]);

  useEffect(() => {
    getOperatorPackages();
  }, [operator_name, isTopUp, i18n.language]);

  if (!isValidateOperatorName) return <PageNotFound />;

  return (
    <Container
      sx={{
        pt: { xs: "80px", lg: "120px" },
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        gap: "50px",
        pb: "68px",
        direction: isRTL ? "rtl" : "ltr",
      }}
      maxWidth={false}
    >
      <Helmet>
        {isTopUp ? (
          <title>{t("titles.simPackagesPage", { operator_sim: t(`${operator_name}_sim`) })}</title>
        ) : (
          <title>{t("titles.simPurchasePage", { operator_sim: t(`${operator_name}_sim`) })}</title>
        )}
      </Helmet>
      {isTopUp && (
        <Header
          image={
            <Box
              component="img"
              alt={t(operator_name)}
              sx={{
                width: "125px",
                aspectRatio: "1 / 1",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
              }}
              src={`/images/operators/with_background/${operator_name}.webp`}
            />
          }
          title={
            <Trans
              i18nKey="simChargeTitle"
              values={{ operator_sim: t(`${operator_name}_sim`) }}
              components={{ b: <b /> }}
            />
          }
          subtitle={
            <Trans
              i18nKey="simChargeSubTitle"
              values={{ operator_sim: t(`${operator_name}_sim`) }}
              components={{ b: <b /> }}
            />
          }
          autoLoadingParagraph={<AutoLoadingParagraph />}
        ></Header>
      )}

      {!isTopUp && operators && (
        <Header
          image={
            <SimCard
              name={operator_name}
              color={operators.find((x) => x.name === operator_name).logo_bg_color}
              sx={{ marginX: "unset", transform: "scale(1.25)", [isRTL ? "marginRight" : "marginLeft"]: "-17px" }}
            />
          }
          title={
            <Trans i18nKey="simCard" values={{ operator_sim: t(`${operator_name}_sim`) }} components={{ b: <b /> }} />
          }
          subtitle={
            <Container
              sx={{
                display: "flex",
                flexDirection: "column",
                "& .MuiContainer-root": {
                  display: "flex",
                  gap: "7px",
                },
                "& .MuiSvgIcon-root": { color: grey[800], marginTop: "3px", fontSize: { xs: "1.25rem", sm: "1.5rem" } },
              }}
              maxWidth={false}
              disableGutters
            >
              <Container maxWidth={false} disableGutters>
                <LocalOfferIcon />
                <Trans i18nKey="simPrice" components={{ b: <b /> }} />
              </Container>
              <Container maxWidth={false} disableGutters>
                <DeliveryDiningIcon />
                <Trans i18nKey="simDelivery" components={{ b: <b /> }} />
              </Container>
              <Container maxWidth={false} disableGutters>
                <Inventory2Icon />
                <Trans i18nKey="simPackage" components={{ b: <b /> }} />
              </Container>
            </Container>
          }
        ></Header>
      )}
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          flex: 1,
          height: "100%",
          justifyContent: "start",
          gap: "25px",
        }}
      >
        {!isLoading && packages ? (
          packages.map((packageInfo) => (
            <React.Fragment key={packageInfo.id}>
              <PackageCardHorizontal
                package_info={packageInfo}
                isTopUp={isTopUp}
                sx={{ display: { xs: "none", lg: "flex" } }}
              />
              <PackageCardVertical
                title={
                  <Typography
                    variant="h5"
                    sx={{ color: "black", fontWeight: "bold", textAlign: isRTL ? "right" : "left" }}
                  >
                    {packageInfo.title}
                  </Typography>
                }
                package_info={packageInfo}
                isTopUp={isTopUp}
                sx={{
                  display: { xs: "flex", lg: "none" },
                  bgcolor: "var(--package-card-color)",
                  padding: "15px",
                  borderRadius: "15px",
                }}
              ></PackageCardVertical>
            </React.Fragment>
          ))
        ) : (
          <CenteredContainer sx={{ flex: 1 }} maxWidth={false}>
            <LoadingSpinner />
          </CenteredContainer>
        )}
      </Container>
    </Container>
  );
}
