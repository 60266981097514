import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import CenteredContainer from "./CenteredContainer";
import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useSharedContext } from "../../SharedContext";
import ROUTES from "../../routes";
import Navigate from "./Navigate";

export default function EmptyCart() {
  const { t } = useSharedContext();
  return (
    <CenteredContainer sx={{ maxWidth: "unset !important", height: "100vh", flexDirection: "column" }}>
      <ProductionQuantityLimitsIcon sx={{ height: "150px", width: "150px", color: grey[500] }} />
      <Typography align="center" sx={{ fontWeight: "bold", fontSize: "1.25rem" }}>
        {t("cartIsEmpty")}
      </Typography>
      <Navigate to={ROUTES.HOME} withDecoration>
        <Typography align="center" sx={{ fontSize: "1.25rem" }}>
          {t("backToHomePage")}
        </Typography>
      </Navigate>
    </CenteredContainer>
  );
}
