import { Container, Typography } from "@mui/material";
import Item from "./Item";
import BuyerInfo from "./BuyerInfo";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSharedContext } from "../../SharedContext";
import EmptyCart from "../Common/EmptyCart";
import { useEffect, useState } from "react";
import API from "../../API";
import LoadingSpinner from "../Common/LoadingSpinner";
import CenteredContainer from "../Common/CenteredContainer";
import { grey } from "@mui/material/colors";

export default function Cart() {
  const { isRTL, t, i18n, cartItems, setCartItems } = useSharedContext();
  const [cartDetails, setCartDetails] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const buildCartDetails = (packages_data) => {
    const arr = [];
    cartItems.forEach((item) => {
      const package_data = packages_data.find((x) => x.id === item.package_id);
      arr.push({
        title: package_data.title,
        price: package_data.price + ("phone_number" in item ? 0 : 30),
        phone_number: item.phone_number,
        operator_name: item.operator_name,
      });
    });
    setCartDetails(arr);
  };

  const getCartDetails = async () => {
    const packages_id = [];
    cartItems.forEach((x) => {
      if (!packages_id.includes(x.package_id)) packages_id.push(x.package_id);
    });
    const packages_data = await API.getPackagesTitleAndPrice(packages_id, i18n.language);
    if (packages_data != null) buildCartDetails(packages_data);
  };

  const deleteCartItem = (index) => {
    const updated_cartItems = [...cartItems];
    const updated_cartDetails = [...cartDetails];
    updated_cartItems.splice(index, 1);
    updated_cartDetails.splice(index, 1);
    setCartItems(updated_cartItems);
    setCartDetails(updated_cartDetails);
  };

  useEffect(() => {
    getCartDetails();
  }, [i18n.language]);

  useEffect(() => {
    if (cartDetails != null) {
      let total = 0;
      cartDetails.forEach((x) => {
        total += x.price;
      });
      setTotalPrice(total.toFixed(1));
    }
  }, [cartDetails]);

  if (cartItems.length === 0) return <EmptyCart />;
  if (totalPrice == null)
    return (
      <CenteredContainer sx={{ height: "100vh" }} maxWidth={false}>
        <LoadingSpinner />
      </CenteredContainer>
    );

  return cartDetails ? (
    <Container
      sx={{
        pt: { xs: "80px", lg: "120px" },
        paddingBottom: "36px",
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        gap: "30px",
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      <Container sx={{ width: { xs: "100%", lg: "375px" }, paddingX: { xs: 3, lg: 0 } }}>
        <Accordion sx={{ m: "0 !important", boxShadow: "unset" }} defaultExpanded={isLargeScreen ? true : false}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              "& .MuiAccordionSummary-content": { m: "0px !important" },
              p: 0,
              minHeight: "48px !important",
              display: "flex",
              gap: "15px",
            }}
          >
            <Container sx={{ display: "flex", alignItems: "center", gap: "5px" }} disableGutters>
              <ReceiptLongIcon />
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {t("orderInformation")}
              </Typography>
            </Container>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            {cartDetails.map((item, i) => (
              <Item
                key={i}
                title={item.title}
                price={item.price}
                phone_number={item.phone_number}
                operator_name={item.operator_name}
                deleteCartItem={() => deleteCartItem(i)}
              />
            ))}
          </AccordionDetails>
        </Accordion>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", mt: "10px", pt: "5px", borderTop: `3px solid ${grey[500]}` }}
        >
          {t("total")} {`(${cartItems.length} ${t("items")})`}:{" "}
          <span style={{ whiteSpace: "pre" }}>{totalPrice} ₪</span>
        </Typography>
      </Container>
      <Container disableGutters>
        <BuyerInfo is_include_delivery={cartDetails.find((x) => x.phone_number == null) != null} />
      </Container>
    </Container>
  ) : (
    <CenteredContainer sx={{ height: "100vh" }} maxWidth={false}>
      <LoadingSpinner />
    </CenteredContainer>
  );
}
