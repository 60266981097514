import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import he from "./components/locales/he.json";
import ar from "./components/locales/ar.json";
import ru from "./components/locales/ru.json";
import en from "./components/locales/en.json";

const userLanguage = localStorage.getItem("language") || "he";

i18n.use(initReactI18next).init({
  resources: {
    he: { translation: he },
    ar: { translation: ar },
    ru: { translation: ru },
    en: { translation: en },
  },
  lng: userLanguage,
  fallbackLng: "he",
  interpolation: { escapeValue: false },
});

export default i18n;
